<template>
  <div id="manage-external-integrations" class="fx-main container">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <modal
      name="modal-bling"
      class="top-modal"
      :height="'60%'"
      :width="'60%'"
    >
      <div class="modal-header fx-main fx-end">
        <i
          class="clickable fa fa-times"
          aria-hidden="true"
          v-on:click="hideModalBling"
        ></i>
      </div>
          <div class="col">
          <div class="card">
            <div class="card-body">
              <label for="token">API Token</label>
              <div class="txt-align-left mg-0" >
                <input type="text" name="token" id="token" v-model="searchParameters.token">
              </div>   
              <label for="deposito">Código Depósito</label>
              <div class="txt-align-left mg-0" >
                <input type="text" name="deposito" id="deposito" v-model="searchParameters.deposito">
              </div>
              <label for="categoria">Código Categoria</label>
              <div class="txt-align-left mg-0" >
                <input type="text" name="catgegoria" id="categoria" v-model="searchParameters.categoria">
              </div>              
              <label for="fator">Fator Preço</label>
              <div class="txt-align-left mg-0" >
                <input type="text" name="fator" id="fator" v-model="searchParameters.fator">
              </div>   
            </div>
          </div>
        </div>
      <pre> {{ logExport }} </pre>
      <button
        class="btn clickable error" type="button"  @click="exportBling()">Exportar
      </button>

    </modal>
    <div class="full-width">
      <span @click="auth('MercadoLivre')">Mercado Livre</span>
    </div>
    <div class="full-width">
      <span @click="auth('PagSeguro')">PagSeguro</span>
    </div>
    <div class="full-width">
      <button class="btn clickable error"  type="button"
        @click="showModalBling()">Bling
      </button>
    </div>
  </div>
</template>

<script>
import Integrations from "@/services/integrations.js";
import util from "@/services/util";
import { apiBaseUrl } from "@/services/api.js";
import axios from "axios";
import HeaderBuilder from "@/services/header-builder.js";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet


export default {
  name: "manage-external-integrations",
  data: function() {
    return {
      loadIndex: 0,
      modalData: {},
      isLoading: false,
      logExport: null,
      searchParameters: {
        token: "180aeed3090f8b402cbed7cb6db1c82d244fe91dd74e437b4b609d7eafd00ff2518814f",
        categoria: "3744836",
        deposito: "14405039518",
        fator: 1.23,
      },
    };
  },
  mounted() {
    if(this.$route.query.system && this.$route.query.code && this.$route.query.redirected_uri) {
      this.loadIndex++;
      Integrations.passCode(this.$route.query.system, this.$route.query.code, this.$route.query.redirected_uri).catch(error => {
        this.$notify({
          type: "error",
          duration : 24000,
          title: "Erro ao realizar autenticação!",
          text: util.stringifyAxiosError(error)
        });
      }).finally(() => {
        this.loadIndex--;
      });
    }
    if(this.$route.query.system === 'PagSeguro' && this.$route.query.notificationCode)
    {
      this.loadIndex++;
      Integrations.passCode(this.$route.query.system, this.$route.query.notificationCode, 'bogus').catch(error => {
        this.$notify({
          type: "error",
          duration : 24000,
          title: "Erro ao realizar autenticação!",
          text: util.stringifyAxiosError(error)
        });
      }).finally(() => {
        this.loadIndex--;
      });
    }
  },
  beforeDestroy() {
    
  },
  methods: {
    auth(system) {
      this.loadIndex++;
      Integrations.auth.call(this, system).catch(error => {
        this.$notify({
          type: "error",
          duration : 24000,
          title: "Erro ao realizar autenticação!",
          text: util.stringifyAxiosError(error)
        });
      }).finally(() => {
        this.loadIndex--;
      });
    },
    showModalBling: function () {
      this.commentText = "";
      this.$modal.show("modal-bling");
    },
    hideModalBling: function () {
      this.$modal.hide("modal-bling");
    },
    exportBling: function () {
      this.isLoading = true;
      const apiUrl =
        `${apiBaseUrl}/api/bling-product?token=${this.searchParameters.token}&&deposito=${this.searchParameters.deposito}&&categoria=${this.searchParameters.categoria}&&fator=${this.searchParameters.fator}`;
      axios
        .get(apiUrl, {
          responseType: "json",
          headers: HeaderBuilder.build(),
        })
          .then(response => {
            this.logExport = response.data.message;
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    Loading
  },
  computed: {
    isLoading_x: function() {      // TBD
      return this.loadIndex > 0;
    }
  }
};
</script>