import HeaderBuilder from '@/services/header-builder.js';
import axios from 'axios';
import {
    apiBaseUrl
} from "@/services/api.js";

const Integrations = {
    auth: async function(system) {
        const response = await axios.get(`${apiBaseUrl}/api/third-party/authorization/${system}?redirectUri=${encodeURIComponent(Integrations.getRedirectUri.call(this, system))}`, {
            headers: HeaderBuilder.build()
        });
        window.location = response.data.redirect;
    },
    passCode: async function(system, code, redirect_uri) {
        const response = await axios.put(`${apiBaseUrl}/api/third-party/authorization/${system}/code/${encodeURIComponent(code)}?redirectUri=${encodeURIComponent(redirect_uri)}`, null, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    getRedirectUri: function getRedirectUri(system) {
        return `${window.location.href.split('?')[0]}?system=${system}`;
    }
};

export default Integrations;